import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'index',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/BMS',
    name: 'BMSindex',
    component: () => import('@/views/BMS/index.vue'),
  },
  {
    path: '/map',
    name: 'mapindex',
    component: () => import('@/views/MapContainer/index.vue'),
  },
  {
    path: '/Electricity',
    name: 'Electricityindex',
    component: () => import('@/views/Electricity/index.vue'),
  }
]
const router = new VueRouter({
  // mode: 'hash',
  routes
})

export default router